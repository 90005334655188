<template>
  <div class="zhihuijiaotong">
    <div class="jieshao mt20">
      基于大数据平台的地理空间数据（一张图）,建筑数据信息，整合交通领域已分散建设的业务系统，把道路、车辆等基础设施和电子、信息、通信等信息技术以及科学的管理策略结合起来，实现智能地控制交通设施，统筹交通，实现交通智能调度，与应急指挥、消防救援、公共安全等智慧城市管理相关业务进行联合管理；给交通使用者提供有用的情报，保障安全、便利的通行，提高整个交通系统的效率，智慧交通管理指挥系统为驾驶员、步行者、运行/管理者提供多种形式的服务。<br />
      智慧交通建设由交通信息采集系统、交通调度系统、交通信息交换平台、交通应用系统（出租车管理子系统、网约车管理子系统、交通设施管理子系统、交通信息发布子系统、城市道路信息管理子系统）等构成。
    </div>
    <div class="img mt30">
      <img src="../../../../assets/imgs/fangan/zhihuijiaotong.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    document.body.scrollTop = 0;
  },
};
</script>

<style lang="scss" scoped>
.zhihuijiaotong {
  width: 870px;
  padding-bottom: 60px;
  .img {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      max-width: 100%;
    }
  }
  .jieshao {
    font-size: 16px;
    font-family: Alibaba PuHuiTi Xi;
    font-weight: 400;
    line-height: 22px;
    color: #181818;
    margin-top: 20px;
  }
  .biaoti {
    font-size: 13px;
    font-family: Alibaba PuHuiTi;
    font-weight: 400;
    line-height: 17px;
    color: #181818;
    display: flex;
    align-items: center;
    margin-left: -6px;
    span {
      margin-right: 8px;
      font-size: 20px;
    }
  }
  .text {
    font-size: 13px;
    font-family: Alibaba PuHuiTi Xi;
    font-weight: 400;
    line-height: 22px;
    color: #666666;
    margin-top: 20px;
  }
  .li {
    width: 100%;
    position: relative;
    padding-left: 14px;
    .dian {
      width: 6px;
      height: 6px;
      background: rgba(68, 112, 255, 1);
      border-radius: 50%;
      position: absolute;
      top: 8px;
      left: 0;
    }
    .li-title {
      font-size: 14px;
      font-family: Alibaba PuHuiTi;
      font-weight: 700;
      line-height: 22px;
      color: #666666;
    }
    .li-text {
      font-size: 13px;
      font-family: Alibaba PuHuiTi Xi;
      font-weight: 400;
      line-height: 22px;
      color: #666666;
    }
  }
}
</style>